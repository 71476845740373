import React from "react"
import styled from "styled-components"
import axios from "axios"
import { navigate, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import CallToAction from "../components/CallToAction"
import {
  HeroTitle,
  Section,
  SectionTitle,
  LeftTriangle,
  RightTriangle,
  BreadCrumb,
  Checkbox,
  HelpText
} from "../components/Section"
import {
  Button,
  CustomValidationMessage,
  RequiredTriangle,
} from "../components/FormStyle"
import { TwoColumns } from "../components/Grid"
import ContactUsCarousel from "../components/ContactUsCarousel"
import AddressIcon from "../components/Icons/AddressIcon"
import EmailIcon from "../components/Icons/EmailIcon"
import CallPhone from "../components/Icons/CallPhone"


const AddressGrid = styled.div`
  margin-top: 66px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  height: 220px;
  @media (max-width: 1024px) {
    display: none;
  }
`
const GridItem = styled.div`
  padding: 0 10%;
  border: 1px solid #98dfff;
  height: 180px;
  display: flex;
  align-items: center;
  & :hover {
    box-shadow: 0px 15px 45px rgba(152, 223, 255, 0.2);
    border-radius: 5px;
    height: 220px;
  }
`
const Icon = styled.div`
  width: 20%;
  display: inline-block;
  vertical-align: middle;
  & svg {
    width: 90%;
  }
  @media (max-width: 1100px) {
    width: 15%;
  }
`
const TextWrap = styled.div`
  width: 80%;
  display: inline-block;
  vertical-align: middle;
  & strong {
    display:block;
    font-size: 24px;
    line-height: 29px;
    color: #062c44;
    margin-bottom: 5px;
    font-family: Chakra Petch, sans-serif;
    font-weight: 700;
  }
  & p {
    text-align: left;
    font-size: 16px;
    line-height: 22px;

    a {
      color: #062c44;
    }

    a:hover {
      color: #EA5F2C;
      text-decoration: underline !important;
    }
  }
  @media (max-width: 1100px) {
    width: 80%;
    margin-left: 5%;
  }
`
const FormWrapper = styled.div`
  position: relative;
  z-index: 51;
  max-width: 906px;
  margin: auto;
  margin-top: 56px;
  & input {
    width: 100%;
    background: #f4fbff;
    border: 0px;
    border-bottom: 1px solid #dce7ef;
    &:focus {
      outline: none;
    }
  }
  & textarea {
    width: 100%;
    background: rgba(0, 0, 0, 0.001);

    border: 0px;
    border-bottom: 1px solid #dce7ef;
    &:focus {
      outline: none;
    }
  }
`
const Label = styled.div`
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
  color: #062c44;
  margin-bottom: 9px;
  margin-left: 2px;
`

const Field = styled.div`
  position: relative;

  textarea {
    resize: none;
  }
`

const CustomRequired = styled(CustomValidationMessage)`
  top: 75px;
  left: -20px;
`

class ContactUsPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      first_name: "",
      last_name: "",
      email: "",
      phone_no: "",
      state: "",
      city: "",
      comment: "",
      agreeViaSms: true,
      fNameRequire: false,
      lNameRequire: false,
      emailRequire: false,
      phoneRequire: false,
      stateRequire: false,
      cityRequire: false,
      commentRequire: false,
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }
  handleChange(event) {
    const { id, value } = event.target

    if (id !== "agreeViaSms") {
      this.setState({
        [id]: value,
      })
    } else {
      this.setState({
        [id]: event.target.checked,
      })
    }
    if (id === "first_name") {
      this.setState({
        fNameRequire: false,
      })
    }
    if (id === "last_name") {
      this.setState({
        lNameRequire: false,
      })
    }
    if (id === "email") {
      this.setState({
        emailRequire: false,
      })
    }
    if (id === "phone_no") {
      this.setState({
        phoneRequire: false,
      })
    }
    if (id === "state") {
      this.setState({
        stateRequire: false,
      })
    }
    if (id === "city") {
      this.setState({
        cityRequire: false,
      })
    }
    if (id === "comment") {
      this.setState({
        commentRequire: false,
      })
    }
    if (id === "agreeViaSms") {
      this.setState({
        agreeViaSmsRequire: false,
      })
    }
  }
  handleSubmit = event => {
    const { location } = this.props
    event.preventDefault()
    if (this.state.first_name === "") {
      this.setState({
        fNameRequire: true,
      })
    } else if (this.state.last_name === "") {
      this.setState({
        lNameRequire: true,
      })
    } else if (this.state.email === "") {
      this.setState({
        emailRequire: true,
      })
    } else if (this.state.phone_no === "") {
      this.setState({
        phoneRequire: true,
      })
    } else if (this.state.state === "") {
      this.setState({
        stateRequire: true,
      })
    } else if (this.state.city === "") {
      this.setState({
        cityRequire: true,
      })
    } else if (this.state.comment === "") {
      this.setState({
        commentRequire: true,
      })
    } else {
      const data = {
        source: location.origin,
        form: 'Contact Form',
        page: location.pathname,
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        email: this.state.email,
        phone_no: this.state.phone_no,
        state: this.state.state_name,
        city: this.state.city,
        comment: this.state.comment + ", SMS consent : " + this.state.agreeViaSms,
        agreeViaSms: this.state.agreeViaSms,
      }
      axios({
        method: "post",
        url: "https://crm.senseicrm.com/api/create-lead-wordpress",
        data: data,
        headers: {
          Authorization:
            "Bearer " +
            "13IMU4ZLF9WyrjRj6JuTfVRwGswQVmfBpO39w7bYvaFT93nbcTKE8PnUBv4bWMrM",
        },
      })
        .then(res => {
          this.setState({
            first_name: "",
            last_name: "",
            email: "",
            phone_no: "",
            zipcode: "",
            state_name: "",
            city: "",
            comment: "",
            agreeViaSms: false,
          })
          navigate("/thank-you-contact-us/")
        })
        .catch(error => {
          alert(error)
        })
    }
  }  
  render() {
    return (
      <Layout location={this.props.location}>
        <SEO title="Contact Us" description="Contact Us" />
        <Section pt="250px" mtp="160px" pb="70px">
          <div className="container">
            <BreadCrumb top="-40px" mTop="-40px">
              <Link to="/">Home</Link> / <span>Contact Us </span>
            </BreadCrumb>
          </div>
          <HeroTitle>Contact Us</HeroTitle>
          <p>
            We want to hear from you. Whether you are buying, looking to buy or
            simply have a question for one of our building experts please give
            us a call or email us. You can also find us on social media!
          </p>
          <div className="container">
            <AddressGrid>
              <GridItem>
                <Icon>
                  <AddressIcon />
                </Icon>
                <TextWrap>
                  <strong>Address</strong>
                  <p><a href="https://maps.app.goo.gl/ypmUL8Sg1Ta7ST3b7" target="_blank" aria-label="maps">3752 W US Hwy 90, Lake City, Fl 32055</a></p>
                </TextWrap>
              </GridItem>
              <GridItem>
                <Icon>
                  <EmailIcon />
                </Icon>
                <TextWrap>
                  <strong>Email Address</strong>
                  <p><a href="mailto:info@probuiltsteel.com">info@probuiltsteel.com</a></p>
                </TextWrap>
              </GridItem>
              <GridItem>
                <Icon>
                  <CallPhone />
                </Icon>
                <TextWrap>
                  <strong>Contact Number</strong>
                  <p><a href="tel:8777541818">1-877-754-1818</a></p>
                </TextWrap>
              </GridItem>
            </AddressGrid>
            <ContactUsCarousel />
          </div>
        </Section>
        <Section pt="138px" pb="0" mbp="0" mplr="0" bg="#F4FBFF">
          <div className="container">
            <SectionTitle>Get In Touch</SectionTitle>
            <p>
              Our professional staff is waiting to help solve your storage
              challenges. We’re happy to answer any question, so please get in
              touch by phone or the form below.
            </p>
            <FormWrapper>
              <form id="contact-form" onSubmit={this.handleSubmit}>
                <TwoColumns gridCol="1fr" colGap="40px" rowGap="45px">
                  <Field>
                    <Label>First Name</Label>
                    <input
                      id="first_name"
                      placeholder="Please Enter Your First Name"
                      onChange={this.handleChange}
                      pattern="[A-Za-z\s]+$"
                    />
                    <CustomRequired
                      display={this.state.fNameRequire ? "block" : "none"}
                    >
                      <RequiredTriangle />
                      <span>Please input this field</span>
                    </CustomRequired>
                  </Field>
                  <Field>
                    <Label>Last Name</Label>
                    <input
                      id="last_name"
                      placeholder="Please Enter Your Last Name"
                      onChange={this.handleChange}
                      pattern="[A-Za-z\s]+$"
                    />
                    <CustomRequired
                      display={this.state.lNameRequire ? "block" : "none"}
                    >
                      <RequiredTriangle />
                      <span>Please input this field</span>
                    </CustomRequired>
                  </Field>
                  <Field>
                    <Label>Email Address</Label>
                    <input
                      id="email"
                      placeholder="Please Enter Your Email Address"
                      onChange={this.handleChange}
                      pattern="^[^\s@]+@[^\s@]+\.[^\s@]+$"
                    />
                    <CustomRequired
                      display={this.state.emailRequire ? "block" : "none"}
                    >
                      <RequiredTriangle />
                      <span>Please input this field</span>
                    </CustomRequired>
                  </Field>
                  <Field>
                    <Label>Phone Number</Label>
                    <input
                      id="phone_no"
                      placeholder="Tell us your Personal Number"
                      onChange={this.handleChange}
                      pattern="[0-9]{10}$"
                    />
                    <CustomRequired
                      display={this.state.phoneRequire ? "block" : "none"}
                    >
                      <RequiredTriangle />
                      <span>Please input this field</span>
                    </CustomRequired>
                  </Field>
                  <Field>
                    <Label>State</Label>
                    <input
                      id="state"
                      placeholder="Please Enter Your State"
                      onChange={this.handleChange}
                      pattern="^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$"
                    />
                    <CustomRequired
                      display={this.state.stateRequire ? "block" : "none"}
                    >
                      <RequiredTriangle />
                      <span>Please input this field</span>
                    </CustomRequired>
                  </Field>
                  <Field>
                    <Label>City</Label>
                    <input
                      id="city"
                      placeholder="Please Enter Your City"
                      onChange={this.handleChange}
                      pattern="^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$"
                    />
                    <CustomRequired
                      display={this.state.cityRequire ? "block" : "none"}
                    >
                      <RequiredTriangle />
                      <span>Please input this field</span>
                    </CustomRequired>
                  </Field>
                </TwoColumns>
                <Field style={{ marginTop: "45px" }}>
                  <Label>Comment</Label>
                  <textarea
                    id="comment"
                    placeholder="Write your comment here…"
                    rows="6"
                    onChange={this.handleChange}
                  />
                  <CustomRequired
                    display={this.state.commentRequire ? "block" : "none"}
                  >
                    <RequiredTriangle />
                    <span>Please input this field</span>
                  </CustomRequired>
                </Field>
                <Field style={{ marginTop: "15px" }}>
                <Checkbox>
                  <label htmlFor="sms"><input type="checkbox" name="sms" value="Yes" defaultChecked="checked"  id="sms"  onChange={this.handleChange} /> I agree that I will be contacted via SMS.</label>
                </Checkbox>
                <HelpText>By clicking 'Submit,' you are agreeing to the Probuilt Steel Buildings Terms of Use and Privacy Policy. You are also consenting to receive phone calls and SMS messages from Probuilt Steel Buildings to provide updates and information regarding your business with Probuilt Steel Buildings. The frequency of messages may vary, and message and data rates may apply. If you wish to opt out of further messaging, reply STOP. For more information, reply HELP. Please refer to our Privacy Policy for details. </HelpText>
                </Field>
                <div style={{ marginTop: "45px", textAlign: "center" }}>
                  <Button type="submit" form="contact-form" width="350px">
                    <div>Submit</div>
                  </Button>
                </div>
              </form>
            </FormWrapper>
          </div>
          <LeftTriangle />
          <RightTriangle />
        </Section>
        <CallToAction />
      </Layout>
    )
  }
}

export default ContactUsPage
